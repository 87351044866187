.container {
  display: flex;
  max-width: 600px;
  flex-flow: row wrap;
  gap: 0.5em;
}

.item {
  flex: 1 1 25%;
}

.item > img,
.item > video {
  max-width: 240px;
  cursor: pointer;
}

.active > img,
.active > video {
  border: 5px solid red;
}

.feature {
  cursor: pointer;
  width: fit-content;
  border: var(--border);
  border-radius: var(--borderRadius);
}

.primary {
  border-color: var(--accent);
  color: var(--accent);
  background-color: var(--accentsoft);
}
