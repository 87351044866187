.wrapper {
  /* padding: 30px 0; */
  margin: 30px 0;
}

.contents {
  display: grid;
  grid-template-columns: repeat(5, 20%);
  text-align: center;
}

.contents a {
  transition: color 0.5s ease-out;
}

.contents a:hover {
  color: var(--accent);
}

.active {
  color: var(--accent);
  border-bottom: 1px solid var(--accent);
  padding-bottom: 5px;
}

/* ADMIN */

.contentsAdmin {
  display: flex;
  flex-direction: row;
  gap: 25px;
}

/* MObile */
.mobileContents {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 1em;
}

.current {
  color: var(--accent);
  font-weight: bold;
}

.directionalLink {
  font-size: 1.5em;
  color: var(--textlight);
}

.directionalLink:hover {
  color: var(--accent);
}
.directionalLink:focus {
  color: var(--textlight);
}


@media screen and (max-width: 725px) {
 /* .wrapper {
  margin-bottom: 0;
 }  */
 .contents > li > a {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
 }
}