.container {
  margin: 0.5em 0.5em 0 0.5em;
  padding: 0.5em;
  color: var(--textgrey);
  background-color: var(--accentdark);
  border: var(--border);
  border-radius: var(--borderRadius);
  display: flex;
  flex-flow: column nowrap;
  gap: 1em;
  font-size: smaller;
}

.list h3 {
  margin-bottom: 0.5em;
}

.skills {
  display: flex;
  flex-flow: row wrap;
  gap: 0.5em;
  width: 100%;
  align-items: center;
  margin-top: 1em;
}

.item {
  padding: 1em;
  border-radius: var(--borderRadius);
  border: var(--border);
  text-align: center;
  background: var(--accentsoft);
  color: var(--textlight);
  border-width: 2px;
  box-shadow: var(--boxShadow);
  cursor: pointer;
}

.secondary {
  border-color: var(--accentsoft);
}

.base {
  border-color: var(--accent);
  color: var(--accent);
}

/* Hover effects */
@media not all and (hover: none) {
  .item:hover {
    color: var(--accent);
    border-color: var(--accent);
  }
  .base:hover {
    color: var(--accentdark);
    font-weight: bold;
    background: var(--accent);
  }
}

@media screen and (max-width: 725px) {
}
