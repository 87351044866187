.container {
  max-width: var(--maxWidthContainer);
  min-width: 400px;
  border-radius: var(--borderRadius);
  background-color: var(--accentdark);
  box-shadow: var(--boxShadow);
  border: var(--border);
  border-width: 2px;
  /* overflow-y: initial !important; */
  /* height: 100vh; */
  min-height: 667px;
  max-height: 800px;
  display: flex;
  flex-direction: column;
}

.contentContainer {
  /* overflow-y: scroll !important; */
  /* text-align: justify; */
  /* width: 100%; */
  /* overflow-x: hidden; */
  padding: 10px 25px;
  display: flex;
  flex-direction: column;
  /* min-width: 100%; */
  /* align-items: center; */
  gap: 0.5em;
  max-height: calc(100vh - 205px);
}

.contentWrapper {
  overflow-y: auto;
  overflow-x: hidden;
}

.contentContainer > h4,
.contentContainer > h5 {
  color: var(--textgrey);
}

.closeWrapper {
  position: absolute;
  display: block;
  right: 5px;
  top: 5px;
  background: transparent;
  border: none;
}

.closeIcon {
  cursor: pointer;
  color: var(--textgrey);
  background-color: var(--accentsoft);
  border-radius: 50%;
  width: 35px;
  height: 35px;
}

@media not all and (hover: none) {
  .closeIcon:hover {
    color: var(--accent);
  }
}

@media screen and (max-width: 725px) {
  .container {
    /* height: 100vh; */
    width: 100vw;
    max-height: 100vh;
    min-width: 0;
    /* min-height: 0;
    min-width: 100vw;
    max-width: none; */
  }
  .contentContainer {
    /* overflow-x: hidden !important; */
    margin-bottom: var(--footerheight);
    padding: 1em;
  }
}
