.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: .5em;
  height: 80vh;
  overflow: auto; ;
}
.inputWrapper {
  display: inline-flex;
  gap: 0.5em;
}
textarea {
  max-width: 500px;
}
.inputWrapper > span {
  font-size: .8em;
  font-style: italic;
}