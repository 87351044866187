.image {
  max-width: 500px;
  max-height: 500px;
  box-shadow: var(--boxShadow);
  border-radius: var(--borderRadius);
  /* flex: 1 0 50%;'' */
}



@media screen and (max-width: 725px) {
  .image {
   width: 100%;
  }
 }