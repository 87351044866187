.container {
  border: var(--border);
  border-radius: var(--borderRadius);
  background: var(--accentdark);
  display: flex;
  flex-flow: column;
  position: relative;
  z-index: -5;
  top: -45px;
  min-width: 250px;
  box-shadow: var(--boxShadow);
  padding: 60px 0.5em 0 0.5em;
}

.icon {
  font-size: 50px;
  min-width: 250px;
  text-align: center;
  padding: 0 9.333px;
}

.icon > svg {
  color: var(--accent);
  border: var(--border);
  border-radius: 50%;
  padding: 0.3em;
  background-color: var(--accentdark);
  box-shadow: var(--boxShadow);
}

.headings {
  text-align: center;
  margin-bottom: 1em;
}

.headings > h3:first-of-type {
  color: var(--textgrey);
}
.headings > h3:last-of-type {
  color: var(--accent);
}

.list {
  margin-top: 1em;
}

.list > li {
  margin-bottom: 1em;
}

.list > li > h5 {
  color: var(--textgrey);
  text-transform: lowercase;
  line-height: 1.5em;
}

.list > li > p > span {
  color: var(--textgrey);
}

@media screen and (max-width: 725px) {
  
  .container {
    /* position: unset; */
    /* min-width: 0; */
    /* max-width: 250px; */
    
  }
  
  .icon {
    position: relative;
    top: 18px;
  }
  
}
