.header {
  background: var(--accentdark);
  border: var(--border);
  border-radius: var(--borderRadius);
  display: flex;
  align-items: center;
  padding: 0.5em;
  margin: 0 0.5em;
}

@media not all and (hover: none) {
  .accordian:hover {
    cursor: pointer;
    background: var(--accentsoft);
  }

  .accordian:hover:hover > .icon {
    color: var(--accentdark);
  }
}

.title {
  color: var(--textgrey);
  flex: 1;
}

.icon {
  color: var(--accentsoft);
  margin: 0 0.5em;
  height: 30px;
}

.listItem {
  margin: 0.5em;
}

.subinfo {
  color: var(--textgrey);
  margin-right: 0.5em;
}

.otherSkills {
  margin-top: 0.5em;
}

/* @media screen and (max-width: 725px) {
  .accordian:active {
    background: var(--accentdark);
  }
  .accordian:active:active > .icon {
    color: var(--accentsoft);
  }
  
}  */
