@import url('https://fonts.googleapis.com/css2?family=Marcellus+SC&family=Quicksand&display=swap');

/* variables */
:root {
  --textlight: #fafafa;
  --textgrey: #888888;
  --accent: #ffda91;
  --accentsoft: #394e5d;
  --accentdark: #102531;
  --background: #29272a;
  --border: 1px solid #707070;
  --borderRadius: 4pt;
  --alphaoverlay: #101a2260;
  --maxWidthContainer: 725px;
  --boxShadow: 3pt 2pt 5pt #101a22;
  --footerheight: 120px;
}

/* base styles */
body {
  font-family: 'Quicksand', sans-serif;
  margin: 0;
  font-size: 14pt;
  background: url('assets/backgroundx2.png'), var(--background);
  background-size: cover;
  color: var(--textlight);
  overflow: hidden;
}
ul,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
ul {
  list-style-type: none;
}

h1,
h2,
h3,
h4,
button,
h5 {
  letter-spacing: 2pt;
  font-weight: normal;
}


h2,
button,
h5 {
  font-family: 'Marcellus SC', serif;
}

a {
  text-decoration: none;
  color: var(--textlight);
}

button {
  cursor: pointer;
}

p {
  white-space: break-spaces;
}

.popup-overlay {
  /* position: absolute !important; */
  /* top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important; */
}

.react-select__menu {
  color: black !important;
}

@media screen and (max-width: 725px) {
  /* body {
    font-size: 14px;
  } */
  body, h3 {
    font-size: 16px;
  }
  h1,h2 {
    font-size: 20px;
  }
}