.link {
  color: var(--accent);
  text-decoration: underline;
}

.contactSection {
  text-align: center;
  margin: 0.5em 0;
}

.contactWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* MOBILE */

@media screen and (max-width: 725px) {
  .wrapper {
    margin: 0 1em 1em 1em;
  }
  .contactSection h4 {
    margin-bottom: 0.5em;
  }
}
