.container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-flow: row wrap; */
  gap: 0.5em;
}

.imageContainer {
  width: 500px;
  height: 350px;
}
.imageContainerMobile {
  width: 350px;
  height: 500px;
}

.selector {
  /* grid-area: selector; */
  align-self: center;
  display: flex;
  flex-flow: row nowrap;
  gap: 0.5em;
}

.select {
  width: 20px;
  height: 20px;
  border: var(--border);
  border-width: 2px;
  border-radius: 50%;
}

.activeSelect {
  background: var(--accentsoft);
}

.icon {
  height: 50px;
  width: 50px;
  color: var(--accentsoft);
  border: 2px solid var(--accentsoft);
  border-radius: 50%;
}

.active {
  background: var(--accentsoft);
  color: var(--textgrey) !important;
  border-color: var(--textgrey) !important;
  cursor: pointer;
}

.active:hover {
  color: var(--accent) !important;
  border-color: var(--accent) !important;
}

.motion {
}

.image {
  width: 100%;
  height: 100%;
  /* max-width: 500px;
  max-height: 500px; */
  box-shadow: var(--boxShadow);
  border-radius: var(--borderRadius);
  /* flex: 1 0 50%;'' */
}

@media screen and (max-width: 725px) {
  .image {
    /* width: 100%; */
  }
  .imageContainer {
    width: 100%;
    height: 100%;
    min-width: 300px;
    min-height: 200px;
    max-width: 500px;
    max-height: 350px;
  }
  .imageContainerMobile {
    width: 100%;
    height: 100%;
    min-width: 200px;
    min-height: 300px;
    max-width: 350px;
    max-height: 500px;
  }
}
