.container {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5em;
  border: 2px solid var(--accentsoft);
  border-radius: var(--borderRadius);
  padding: 0.5em 0.5em 0.5em 1em;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  background-color: var(--textgrey);
  box-shadow: var(--boxShadow);
}
.title {
  color: var(--accent);
  text-transform: uppercase;
}

.subtitle {
  color: var(--accent);
}

.subinfo {
  color: var(--textgrey);
}

.description {
  max-width: 75%;
}

.skillHeader {
  color: var(--textgrey);
}

@media not all and (hover: none) {
  .container:hover {
    border-color: var(--accent);
  }
}
@media screen and (max-width: 725px) {
  .container:focus-visible {
    outline: none;
  }
  .container:active {
    border-color: var(--accent);
  }
}
