.wrapper {
  display: flex;
  gap: 20pt;
}
.filters {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.list {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

/* MOBILE */

@media screen and (max-width: 725px) {
  .wrapper {
    flex-direction: column;
    margin: 0 1em 1em 1em;
  }

  .filters {
    flex-direction: row;
  }
}
