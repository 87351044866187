.App {
  /* height: 100vh; */
  overflow: hidden;
}
.App .container {
  max-width: var(--maxWidthContainer);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

main.content-container {
  flex-grow: 1;
  overflow: auto;
}

main.admin {
  overflow: auto;
}

@media screen and (max-width: 725px) {
  main.content-container {
    position: relative;
  }
}
