.base {
  color: var(--textlight);
  width: 100%;
  height: 50px;
  border-radius: var(--borderRadius);
  border: var(--border);
  border-width: 2px;
  border-color: var(--accentsoft);
  background: var(--accentsoft);
  cursor: pointer;
  box-shadow: var(--boxShadow);
}
@media not all and (hover: none) {
  .base:hover {
    border-color: var(--textgrey);
  }
}

.active {
  box-shadow: inset var(--boxShadow);
  background: var(--accentdark);
}
