.container {
  display: flex;
  flex-flow: row wrap;
  gap: 0.5em;
}

.tag {
  background-color: var(--accentsoft);
  border-radius: var(--borderRadius);
  padding: 5px;
  border: var(--border);
}

.primary {
  border-color: var(--accent);
  color: var(--accent);
}
